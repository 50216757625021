<template>
  <div class="trading-platforms">
    <div class="section section-1">
      <b-container>
        <b-row class="">
          <b-col cols="12" md="6" class="box-tr-pl">
            <h2 class="title">Platforms and tools</h2>
            <p class="mini-title">
              The world's most advanced technology trading platform gives users the ultimate
              experience regardless of time and space with high reliability and speed.
            </p>
          </b-col>
          <b-col cols="12" md="6">
            <img src="~@/assets/images/background/pl.jpeg" alt="" />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-2">
      <b-container>
        <b-row class="">
          <b-col cols="12" md="6">
            <img src="~@/assets/images/background/ct.png" alt="" />
          </b-col>
          <b-col cols="12" md="6" class="box-tr-d">
            <h2 class="title">Our Platforms</h2>
            <p class="mini-title">
              Our platform is available on two popular operating systems: Windows and Mac OS on any
              smart device with an internet connection and on a web browser, WebTrader.
            </p>
            <p class="mini-title">Freedom to own a personal investment channel</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-3">
      <b-container>
        <b-row>
          <b-col cols="12">
            <h2 class="title">Autochartist trading tools</h2>
            <p class="mini-title">
              Access hundreds of trading opportunities based on key support and resistance levels
              with this powerful pattern recognition tool.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-7">
      <Started />
    </div>
  </div>
</template>

<script>
import Started from '@/components/shared/Started.vue';

export default {
  components: { Started },
};
</script>

<style lang="scss">
.trading-platforms {
  h2.title {
    font-size: clamp(1.2rem, 3vw, 2.2rem);
  }
  .section-1 {
    background: url('~@/assets/images/background/1.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    background-attachment: fixed;
    padding: 150px 0 50px;
    min-height: 30vh;
    position: relative;
    img {
      width: 100%;
      height: 320px;
      object-fit: cover;
      border-radius: 5px;
    }
    .box-tr-pl {
      padding: 0 2rem;
      color: #fff;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      h2 {
        margin-bottom: 40px;
      }

      .mini-title,
      h2 {
        text-align: left;
        text-align-last: left;
      }
      @media (max-width: 767px) {
        .mini-title,
        h2 {
          text-align: center;
          text-align-last: center;
          margin: 20px auto 15px;
        }
        .mini-title {
          font-size: 16px;
          margin-bottom: 25px;
        }
      }
      @media (max-width: 576px) {
        padding: 5px 15px!important;
      }
    }
    @media (max-width: 576px) {
      padding: 100px 0 5px;
      .col-12 {
        padding: 0;
      }
      img {
        object-position: 40% center;
      }
    }
  }

  .section-2 {
    overflow: hidden;
    background-color: #f2f4f6;
    img {
      width: 100%;
      height: 320px;
      object-fit: contain;
    }
    .box-tr-d {
      padding: 0 2rem;
      color: #333;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      h2 {
        margin-bottom: 40px;
      }

      .mini-title,
      h2 {
        text-align: left;
        text-align-last: left;
      }
      @media (max-width: 767px) {
        .mini-title,
        h2 {
          text-align: center;
          text-align-last: center;
          margin: 20px auto 15px;
        }
        .mini-title {
          font-size: 16px;
          margin-bottom: 25px;
        }
      }
      @media (max-width: 576px) {
        .title {
          font-size: clamp(1.6rem, 3vw, 2.7rem);
        }
        .content {
          font-size: 1rem;
          font-weight: 500;
        }
        .mini-title {
          margin: 0 0 15px;
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 20px 0;
    }
  }
  .section-3 {
    .mini-title,
    h2.title {
      text-align: center;
      max-width: 840px;
      width: 80%;
      margin: {
        left: auto;
        right: auto;
      }
      text-transform: capitalize;
    }
    h2.title {
      font-size: clamp(1.2rem, 3vw, 1.8rem);
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 15px;
    }
    .mini-title {
      font-size: 1.125rem;
      font-weight: 600;
      text-transform: capitalize;
      text-align: justify;
      line-height: 120%;
      text-align-last: center;
    }
    @media (max-width: 1440px) {
      padding: 40px 0;
    }
    @media (max-width: 576px) {
      h2.title {
        text-align: center;
      }
      .mini-title {
        text-align: center;
        font-size: 0.9rem;
      }
    }
  }
  .section-7 {
    padding: 0px 0 1px;
  }
  .bg-od {
    background-color: #f2f4f6;
  }
}
</style>
